import instance2 from "../Utils/instance2"

export function updateField(body, offline) {
	const request = {
		method: 'put',
		url: 'def_checklists/fields',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function cloneFieldCategory(body, offline) {
	const request = {
		method: 'post',
		url: 'def_checklists/field_categories',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function deleteFieldCategory(body, offline) {
	const request = {
		method: 'delete',
		url: 'def_checklists/field_categories',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const answerCategoryAsValue = (body, offline) => {
	const request = {
		method: 'put',
		url: 'def_checklists/categories/answer_as_value',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function answerChecklistItem(body, offline) {
	const request = {
		method: 'put',
		url: 'def_checklists/items/answer',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function setChecklistParticipants(body, offline) {
	const request = {
		method: 'put',
		url: 'def_checklists/set_participants',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function signChecklistGroup(body, offline) {
	const request = {
		method: 'post',
		url: 'def_checklists/signs',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function closeChecklist(body, offline) {
	const request = {
		method: 'put',
		url: 'def_checklists/close',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function getChecklistGroupSign(body) {
	return instance2({
		method: 'get',
		url: 'def_checklists/signs',
		params: body
	})
}

export function deleteChecklistSign(body, offline) {
	const request = {
		method: 'delete',
		url: 'def_checklists/signs',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function cloneItemsCategory(body, offline) {
	const request = {
		method: 'post',
		url: 'def_checklists/categories',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function deleteItemsCategory(body, offline) {
	const request = {
		method: 'delete',
		url: 'def_checklists/categories',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function updateItemsCategory(body, offline) {
	const request = {
		method: 'put',
		url: 'def_checklists/categories',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function createCategoryGroup(body, offline) {
	const request = {
		method: 'post',
		url: 'def_checklists/category_groups',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function updateCategoryGroup(body, offline) {
	const request = {
		method: 'put',
		url: 'def_checklists/category_groups',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function deleteCategoryGroup(body, offline) {
	const request = {
		method: 'delete',
		url: 'def_checklists/category_groups',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export function getChecklistForManagementReport(body) {
	return instance2.get('def_checklists/management_report', { params: body })
}

export function toggleCommitteeChecklistFlag(body) {
	return instance2.put('def_checklists/committee_flag', body)
}

export function getChecklistsSchedule(body) {
	return instance2.get('def_checklists/schedule', { params: body })
}

export function createFromBaseChecklist(body) {
	return instance2.post('def_checklists/create_from_base_id', body)
}

export function signChecklistWithFinger(body) {
	return instance2.post('def_checklists/sign_with_finger', body)
}

export function signChecklistWithOTP(body) {
	return instance2.post('def_checklists/sign_with_otp', body)
}

export function downloadChecklist(params) {
	const responseType = params.format !== "url" ? 'blob' : 'json'
	return instance2.get('def_checklists/download', { params, responseType })
}

export function createOfflineChecklist(body) {
	return instance2.post('def_checklists/create_offline', body)
}

export function notifyResponsibles(body) {
	return instance2.post('def_checklists/notify_responsibles', body)
}

export function customNotifyFinish(body) {
	return instance2.post('def_checklists/custom_notify_finish', body)
}