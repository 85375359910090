import moment from "moment"
import { uploadFile } from "../API/users"
import { transformFindingFormToPlain } from "../Components/Findings/Card/ExtraFieldsDialog"
import { answerCategoryAsValue, answerChecklistItem, cloneFieldCategory, cloneItemsCategory, closeChecklist, createCategoryGroup, createOfflineChecklist, deleteCategoryGroup, deleteChecklistSign, deleteFieldCategory, deleteItemsCategory, setChecklistParticipants, signChecklistGroup, signChecklistWithOTP, updateCategoryGroup, updateField, updateItemsCategory } from "../API/definitive_checklists"
import { approveFinding, assignFinding, createNewFinding, deleteFinding, dismissFinding, reEstimateFinding, rejectFinding, solveFinding, updateFindingForm } from "../API/findings"
import { answerChecklistFieldFast, signChecklistGroupRegistered, toggleCategoryDisable, toggleCategoryUserDisable } from "../API/new_checklists"

export async function fromObjectURLToFile(objectURLs) {
	// Function to convert a single Object URL to a File
	async function convertURLToFile(url) {
		const file_name = url.split("|&|")[1] || ""
		const url_string = url.split("|&|")[0]
		try {
			console.log("DELETING")
			const response = await fetch(url_string);
			const blob = await response.blob();
			const fileName = Date.now() + file_name
			URL.revokeObjectURL(url_string)
			return new File([blob], fileName, { type: blob.type });
		} catch (error) {
			console.error(error);
		}
	}

	// Check if the input is an array or a single string
	if (Array.isArray(objectURLs)) {
		const files = await Promise.all(objectURLs.map(url => convertURLToFile(url)));
		return files;
	} else if (typeof objectURLs === 'string') {
		return await convertURLToFile(objectURLs);
	} else {
		throw new Error('Invalid input: Expected a string or an array of strings.');
	}
}

export async function fromBlobObjectURLToUploadedFile(blob_object_urls) {
	// Split string into array of strings
	const files = blob_object_urls.split("&#&")
	const uploadPromises = []

	for (const file of files) {
		// Check if file is already uploaded
		if (file.includes("amazonaws")) {
			uploadPromises.push(Promise.resolve(file)) // Push the file as it is
			continue
		}

		// Upload file otherwise
		const uploadPromise = fromObjectURLToFile(file).then(new_file => {
			if (!new_file) throw new Error("Error uploading file")
			const file_body = new FormData()
			file_body.append("file", new_file)
			file_body.append("name", new_file.name)
			return uploadFile(file_body)
		}).then(response => response.data.info)
			.catch(() => null)

		uploadPromises.push(uploadPromise)

	}

	const uploadedFiles = await Promise.all(uploadPromises)
	const uploaded_files_string = uploadedFiles.join("&#&")
	return uploaded_files_string
}

/**
 * Transforms an offline request body to an online plain body.
 * @param {Object} body - The offline request body.
 * @returns {Promise<Object>} - The transformed online plain body.
 */
export async function transformOfflineRequestToOnlinePlain(body) {
	const new_body = { ...body }

	// Remove any "form" key for data consistency
	if (new_body.form) delete new_body.form

	const values = Object.values(new_body)
	const hasBase64Strings = values.some(v => String(v).includes("blob:"))
	if (!hasBase64Strings) return new_body
	for (const key in new_body) {
		const isBase64String = String(new_body[key]).includes("blob:")
		if (isBase64String) {
			new_body[key] = await fromBlobObjectURLToUploadedFile(new_body[key])
		}
	}
	return new_body
}

export function createDataFromJson(json) {
	const body = new FormData()
	Object.keys(json).forEach(key => {
		if (Array.isArray(json[key])) {
			json[key].forEach(value => {
				body.append(`${key}[]`, value === null ? "" : value)
			})
		} else {
			body.append(key, json[key] === null ? "" : json[key])
		}
	})
	return body
}

export async function transformOfflineFormRequestToOnlinePlain(body) {
	const new_body = { ...body }
	let new_form = new_body.form

	// Run the following only if form is in the body

	if (!!new_form) {
		// If form is a JSON string, parse it
		if (typeof new_form === "string") {
			new_form = JSON.parse(new_form)
		}

		const new_form_plain = await transformFindingFormToPlain(new_form)
		new_body.form = JSON.stringify(new_form_plain)
	}

	const values = Object.values(new_body)
	const hasBase64Strings = values.some(v => String(v).includes("blob:"))
	if (!hasBase64Strings) return new_body
	for (const key in new_body) {
		const isBase64String = String(new_body[key]).includes("blob:")
		if (isBase64String) {
			new_body[key] = await fromObjectURLToFile(new_body[key])
		}
	}
	return new_body
}

export async function fileToBlobURL(file) {
	return URL.createObjectURL(file)
}



/**
 * Transforms the body and sends a callback.
 * @param {any} body - The body to be transformed.
 * @param {string} callback_name - The name of the callback.
 * @param {object} context - The context object.
 * @param {string} name - The name of the action. Default is "Offline Sync".
 * @param {string} type - The type of the action. Default is "plain", types can be "plain" | "form".
 * @returns {Promise<void>} - A promise that resolves when the action is added and the event is dispatched.
 */
export async function transformBodyAndSendCallback(body, callback_name, context, name, type = "plain") {

	const { addAction } = context

	const info = {
		name: name || "Offline Sync",
		callback: callback_name,
		body,
		date: moment().format("YYYY-MM-DD HH:mm"),
		status: "pending",
		deployed: null,
		type
	}

	addAction(info)
	const newEvent = new CustomEvent("new_action")
	window.dispatchEvent(newEvent)
}

export const offlineFunctionsMap = {
	answer_checklist_item: answerChecklistItem,
	create_finding: createNewFinding,
	answer_field_fast: answerChecklistFieldFast,
	answer_field: updateField,
	create_sheet: createCategoryGroup,
	update_sheet: updateCategoryGroup,
	remove_sheet: deleteCategoryGroup,
	clone_category: cloneFieldCategory,
	delete_category: deleteFieldCategory,
	toggle_category: toggleCategoryDisable,
	toggle_category_user: toggleCategoryUserDisable,
	mark_all: answerCategoryAsValue,
	duplicate_items_category: cloneItemsCategory,
	delete_items_category: deleteItemsCategory,
	update_items_category: updateItemsCategory,
	delete_checklist_sign: deleteChecklistSign,
	add_checklist_sign: signChecklistGroup,
	add_checklist_sign_otp: signChecklistWithOTP,
	add_checklist_registered_sign: signChecklistGroupRegistered,
	set_checklist_participants: setChecklistParticipants,
	close_checklist: closeChecklist,
	assign_finding: assignFinding,
	solve_finding: solveFinding,
	approve_finding: approveFinding,
	reject_finding: rejectFinding,
	dismiss_finding: dismissFinding,
	delete_finding: deleteFinding,
	update_finding_form: updateFindingForm,
	re_estimate_finding: reEstimateFinding,
	create_checklist: createOfflineChecklist
}