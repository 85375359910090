import { Box, Typography } from '@mui/material'
import { info_box_grey, info_box_white, inline_buttons, link_style, text_space } from '../../../../Utils/defaultStyles'
import { AccountCircleOutlined, NotesOutlined, ScheduleOutlined } from '@material-ui/icons'
import moment from 'moment'
import { InfoRow, ImageSection } from './CardInfoLayouts'
import { useTranslation } from 'react-i18next'
import { extractNameFromUrl } from '../../../Checklist/Content/Form/DocumentField'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

function SolutionInfo({ finding, urls = [] }) {

	const images = !!finding?.solution_evidence ? finding?.solution_evidence?.split("&#&") : []
	const { t } = useTranslation("findingCardInfo")

	function openDocument() {
		window.open(finding.solution_file, "_blank")
	}

	return (
		<Box>
			<Typography variant='h4' sx={text_space} >{t('data.resolution')}:</Typography>
			<Box sx={info_box_grey}>
				<InfoRow icon={AccountCircleOutlined} label={t('data.resolvedBy')} value={finding?.user_solver} />
				<InfoRow icon={ScheduleOutlined} label={t('data.dateSolved')} value={moment(finding?.date_solved).format("DD-MM-YYYY HH:mm")} />
				<InfoRow icon={NotesOutlined} label={t('data.solvedComment')} />
				<Box sx={{ ...info_box_white, marginBottom: 3 }}>
					<Typography variant='subtitle1'>{finding?.solution_comment}</Typography>
				</Box>

				{!!finding?.solution_evidence &&
					<Box sx={{ ...info_box_white, marginBottom: 2 }}>
						<ImageSection images={images} baseUrl={urls[0]} alt={t('alt')} />
					</Box>
				}
				{!!finding?.solution_file &&
					<Box sx={inline_buttons}>
						<InsertDriveFileIcon color='info' />
						<Typography variant='subtitle2' sx={link_style} onClick={openDocument}>{extractNameFromUrl(finding?.solution_file)}</Typography>
					</Box>
				}
			</Box>
		</Box >
	)
}

export default SolutionInfo