import { Box, Button, IconButton, Menu, MenuItem, Switch, Tooltip, Typography } from '@mui/material'
import { useContext, useRef, useState, useEffect } from 'react'
import { ChecklistContext } from '../../Show/ChecklistView'
import AppContext from '../../../../AppContext'
import useToggle from '../../../../Hooks/ToogleHook'
import SubtitleNotificationBell from './SubtitleNotificationBell'
import { Delete, FileCopyOutlined, InfoOutlined, KeyboardArrowDown, MoreVert } from '@material-ui/icons'
import { CategoryOutlined } from '@mui/icons-material'
import newTheme from '../../../../newTheme'
import { end_flex_buttons, flex_center, inline_buttons, inline_space } from '../../../../Utils/defaultStyles'
import Item from './Item'
import CategoryUsers from './CategoryUsers'
import { v4 as uuid } from 'uuid'
import { deepClone } from '../../../../Utils/functions'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import { transformBodyAndSendCallback } from '../../../../Utils/offlineSyncFunctions'
import { useActionContext } from '../../../../DatabaseManagers/ActionsManager'
import { useTranslation } from 'react-i18next'
import CategoryCompliance from './CategoryCompliance'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

const css = {
	header: {
		...inline_space,
		marginBottom: 2
	},
	users: {
		background: 'white',
		borderRadius: 2,
		position: 'relative',
		padding: 1,
		margin: '6px 0'
	},
	category: {
		margin: '30px 0 6px 0'
	},
}

function ItemsCategory({ category, items }) {
	const { checklist, isAdmin, isResponsible, setChecklist, finished, isPublic, appendSave, resolveSave, highlighted, setHighlighted } = useContext(ChecklistContext)
	const { account: user, online: { status: online } } = useContext(AppContext)
	const [openMenu, toggleMenu] = useToggle(false)
	const actionContext = useActionContext()
	const menu = useRef(null)

	const hasPrivileges = isAdmin || isResponsible
	const subtitle_users = category.users || []
	const users = subtitle_users
	const iAmDisabled = !!users.find(u => u.user_id === parseInt(user?.id, 10))?.disabled && !isAdmin
	const iAmCategoryResponsible = users.map(u => u.user_id).includes(parseInt(user?.id, 10)) && !iAmDisabled
	const iAmResponsible = isAdmin || iAmCategoryResponsible || (isResponsible && subtitle_users.length === 0)
	const isCategoryDisabled = category.disabled
	const shouldDisableCategory = isCategoryDisabled || !iAmResponsible
	const shouldDisableUsersSelected = isCategoryDisabled && !hasPrivileges
	const allItems = checklist.checklist_items2?.length
	const showStartClosed = shouldDisableCategory || allItems > 50

	const [open, toggleOpen] = useToggle(!showStartClosed)
	const [showDetail, toggleDetail] = useToggle(false)
	const [name, setName] = useState(category.name)
	const { t } = useTranslation('checklistItem')

	async function onToggleCategory() {
		const body = { id: category.id }
		const categories = checklist.categories || []
		const new_categories = [...categories]
		const new_category = new_categories.find(c => c.id === category.id)
		new_category.disabled = !new_category.disabled
		setChecklist({ ...checklist, categories: new_categories })
		if (isPublic) return

		appendSave()
		await transformBodyAndSendCallback(body, "toggle_category", actionContext, t('actions.enableCategory'))
		resolveSave()

	}

	useEffect(() => {
		if (highlighted?.element?.checklist_category_id == category.id) {
			toggleOpen(true)
		}
	}, [highlighted])

	async function onToggleUser(user_id) {
		const categories = checklist.categories || []
		const new_categories = [...categories]
		const users = category.users
		const new_users = [...users]
		const new_user = new_users.find(u => u.id === user_id)
		new_user.disabled = !new_user.disabled
		new_categories.find(c => c.id === category.id).users = new_users
		const body = {
			id: category.id,
			user_id: user_id
		}
		setChecklist({ ...checklist, categories: new_categories })
		if (isPublic) return
		appendSave()
		await transformBodyAndSendCallback(body, "toggle_category_user", actionContext, t('actions.enableResponsible'))
		resolveSave()
	}

	async function onAnswerCategoryAsValue(value) {
		const body = { id: category.id }
		body.checklist_id = checklist.id
		const new_checklist = { ...checklist }
		const new_items = [...checklist.checklist_items2]
		const category_id = body.id === 0 ? null : body.id
		const value_id = value === "SI" ? 1 : 3
		const items_to_answer = new_items.filter(item => item.checklist_category_id === category_id)
		for (const item of items_to_answer) {
			item.answer = { ...item.answer, value_id, value }
		}
		new_checklist.checklist_items2 = new_items
		setChecklist(new_checklist)
		if (isPublic) return
		appendSave()
		await transformBodyAndSendCallback(body, "mark_all", actionContext, t('actions.markCategory'))
		resolveSave()
		if (highlighted?.element?.checklist_category_id == category.id) {
			setHighlighted(null)
		}
	}

	async function onCloneItemsCategory() {
		const new_category = { ...category }
		new_category.id = uuid()
		new_category.temp_id = new_category.id

		// Clone items now
		const new_items = deepClone(items)
		for (const item of new_items) {
			item.id = uuid()
			item.temp_id = item.id
			item.checklist_category_id = new_category.id
			const new_item_answer = { ...item.answer }

			// Clean the answer set everything to null
			const clean_item_answer = Object.keys(new_item_answer).reduce((acc, key) => {
				acc[key] = null
				return acc
			}, {})

			clean_item_answer.id = uuid()
			clean_item_answer.temp_id = clean_item_answer.id
			clean_item_answer.value_id = null
			clean_item_answer.value = null
			clean_item_answer.checklist_item_id = item.id
			item.answer = clean_item_answer
		}
		const new_checklist = { ...checklist }
		new_checklist.checklist_items2 = [...new_checklist.checklist_items2, ...new_items]
		new_checklist.categories = [...new_checklist.categories, new_category]
		const body = { category: new_category, items: new_items, checklist_id: checklist.id }
		setChecklist(new_checklist)
		toggleMenu(false)
		if (isPublic) return
		appendSave()
		await transformBodyAndSendCallback(body, "duplicate_items_category", actionContext, t('duplicateCategory'))
		resolveSave()
	}

	async function onDeleteItemsCategory() {
		const body = { id: category.id }
		const new_checklist = deepClone(checklist)
		new_checklist.checklist_items2 = new_checklist.checklist_items2.filter(item => item.checklist_category_id !== category.id)
		new_checklist.categories = new_checklist.categories.filter(c => c.id !== category.id)
		setChecklist(new_checklist)
		toggleMenu(false)
		if (isPublic) return

		appendSave()
		await transformBodyAndSendCallback(body, "delete_items_category", actionContext, t('actions.deleteCategory'))
		resolveSave()
	}

	async function onUpdateCategory() {
		const body = { id: category.id, name: name }
		const new_category = { ...category, name }
		const new_checklist = { ...checklist }
		new_checklist.categories = new_checklist.categories.map(c => c.id === category.id ? new_category : c)
		setChecklist(new_checklist)
		if (isPublic) return

		appendSave()
		await transformBodyAndSendCallback(body, "update_items_category", actionContext, t('update.deleteCategory'))
		resolveSave()
	}

	const canCloneCategory = category?.allow_clone && !finished && !isPublic
	const showSwitch = isAdmin && !!category.id && !finished
	const subtitleProgress = items.length > 0 ? (items.filter(item => !!item.answer.value_id).length / items.length * 100).toFixed(1) : 100
	const subtitleCompliance = items.length > 0 ? (items.filter(item => item.answer.value_id === 1 || item.answer.value_id === 3).length / items.length * 100).toFixed(1) : 0
	const toggleShowButtonStyle = { color: 'white', transition: "all 0.2s ease-in-out", transform: `rotate(${open ? 180 : 0}deg)` }
	// Check if there are more than one category named the same in the group
	const canDeleteCategory = category?.allow_clone && !!category?.temp_id

	if (!items.length) return null
	return (
		<Box sx={css.category}>
			<Box sx={css.header}>
				<Box sx={{ ...flex_center, opacity: shouldDisableCategory ? 0.7 : 1 }}>
					<CategoryOutlined color={shouldDisableCategory ? "disabled" : "secondary"} sx={{ marginRight: 2 }} />
					{canCloneCategory && !shouldDisableCategory ?
						<SeamlessInput
							variant="h4"
							fontColor={newTheme.palette.secondary.main}
							value={name}
							onChange={e => setName(e.target.value)}
							onBlur={onUpdateCategory}
							color="transparent"
							padding={0}
						/>
						:
						<Typography variant='h4' color={shouldDisableCategory ? "disabled" : "secondary"}>{category.name}</Typography>
					}
				</Box>
				<Box sx={{ ...inline_buttons, justifyContent: 'flex-end' }}>
					{!isCategoryDisabled &&
						<IconButton onClick={toggleDetail} size='small'>
							<InfoOutlined />
						</IconButton>
					}
					{showSwitch && online && <Switch onClick={onToggleCategory} checked={!shouldDisableCategory} />}
					{canCloneCategory &&
						<IconButton onClick={toggleMenu} size='small' ref={menu}>
							<MoreVert />
						</IconButton>
					}
					<Box sx={{ background: newTheme.palette.info.main, borderRadius: '50%' }}>
						<IconButton color="white" size="small" onClick={toggleOpen} sx={toggleShowButtonStyle}>
							<KeyboardArrowDown />
						</IconButton>
					</Box>
				</Box>
			</Box>

			{!isCategoryDisabled && <CategoryCompliance showDetail={showDetail} subtitleProgress={subtitleProgress} subtitleCompliance={subtitleCompliance} />}

			{!!users.length && hasPrivileges && online && !isPublic &&
				<Box sx={css.users}>
					<Box sx={inline_space}>
						<Typography variant='subtitle1'><strong>{t('responsibles')}</strong></Typography>
						<SubtitleNotificationBell checklist={checklist} categories={[category]} />
					</Box>
					<CategoryUsers users={users} onToggleUser={onToggleUser} disabled={shouldDisableUsersSelected} />
				</Box>
			}

			{open &&
				<>
					{hasPrivileges &&
						<Box sx={{ ...end_flex_buttons }}>
							<Tooltip title={t('actions.markCategoryChecked')}>
								<Button color="success" variant="outlined" size="small" onClick={() => onAnswerCategoryAsValue("SI")} disabled={shouldDisableCategory}>
									<ChecklistRtlIcon />
								</Button>
							</Tooltip>
							<Tooltip title={t('actions.markCategoryNA')}>
								<Button color="info" variant="outlined" size="small" onClick={() => onAnswerCategoryAsValue("N/A")} disabled={shouldDisableCategory}>
									N/A
								</Button>
							</Tooltip>
						</Box>
					}
					{items.map(item => <Item key={item.id} disabled={shouldDisableCategory} item={item} />)}
				</>
			}
			<Menu anchorEl={menu.current} open={openMenu} onClose={toggleMenu}>
				<MenuItem sx={inline_buttons} onClick={onCloneItemsCategory}>
					<FileCopyOutlined />
					<Typography variant='subtitle2'>{t('category.duplicate')}</Typography>
				</MenuItem>
				<MenuItem sx={inline_buttons} onClick={onDeleteItemsCategory} disabled={!canDeleteCategory}>
					<Delete color="error" />
					<Typography variant='subtitle2' color="error.main" >{t('category.delete')}</Typography>
				</MenuItem>
			</Menu>
		</Box>
	)
}

export default ItemsCategory